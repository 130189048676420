<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { useToast } from '~/components/ui/toast'

interface Emits {
  (e: 'success'): void
  (e: 'isBusy', v: boolean): void
}
interface Props {
  showTemplateLinks?: boolean
  size?: 'small' | 'large'
}

withDefaults(defineProps<Props>(), { size: 'small' })
const emit = defineEmits<Emits>()

const { toast } = useToast()
const { $api, $event } = useNuxtApp()
const profileStore = useProfileStore()
const { organisationId } = storeToRefs(profileStore)

const isBusy = ref(false)

const { mutate: uploadFn, isPending: isUploading } = useMutation({
  mutationFn: $api.banking.bills.importBillsInBulk,
})

function uploadBulkBillsFile(file: File) {
  if (!organisationId.value) return

  emit('isBusy', true)

  uploadFn(
    { file, organisationId: organisationId.value },
    {
      onSuccess(response) {
        const successCount = response.data.total_imported
        const message = `${successCount} ${successCount > 1 ? 'bills' : 'bill'} created successfully`
        toast({
          title: 'Bulk bills uploaded',
          description: message,
        })
        $event('track:mixpanel', {
          event: 'Bulk bills uploaded',
          data: {},
        })
        emit('success')
        emit('isBusy', false)
      },
      onError(err: any) {
        const errMsg =
          err?.response?.data?.error_message ||
          'Error occured while uploading bills file'
        toast({
          title: 'Bills Upload',
          description: errMsg,
          variant: 'destructive',
        })

        emit('isBusy', false)
      },
    },
  )
}
</script>

<template>
  <div>
    <!-- <div v-if="isUploading || isBusy" class="py-5">
      <div class="flex justify-center">
        <SharedTheIcon icon-name="spinner" size="m" class-name="animate-spin" />
      </div>
      <p
        class="mt-4 text-center text-sm font-medium leading-[20px] text-primary"
      >
        Uploading bills file...
      </p>
    </div> -->
    <div class="rounded-lg bg-[#F8F9FA] p-5 text-center">
      <div class="text-base font-bold text-primary">
        Upload your invoices or an updated template
      </div>
      <div class="my-4 bg-white" :class="[]">
        <div
          v-if="isUploading || isBusy"
          class="flex h-48 flex-col justify-center gap-4"
        >
          <div class="flex justify-center">
            <SharedTheIcon
              icon-name="spinner"
              size="m"
              class-name="animate-spin"
            />
          </div>
          <p
            class="text-center text-sm font-medium leading-[20px] text-primary"
          >
            Uploading your invoices
          </p>
        </div>
        <UploadsFileUploader
          v-else
          :multiple="false"
          accept=".csv,.xlsx"
          @files="(files) => uploadBulkBillsFile(files[0])"
        >
          <template #accept-text>Only CSV and XLSX allowed.</template>
        </UploadsFileUploader>
      </div>
    </div>
  </div>
</template>
